import { useEffect, useState } from 'react'
import { useQuery } from "@tanstack/react-query"

import { showLoading } from 'services/util'
import { formatPhone } from 'admin/adminutil'

import { urlQueryParser } from '../../util/urlQueryParser'

import { adminGetAuctionByCode, formatName } from '../auctionsadminutil'

import css from './sass/liveauction.module.scss'
import themeCss from '../../components/sass/theme.module.scss'
import LiveAuctionRegistrationRow from './LiveAuctionRegistrationRow'

const attendeeHeaders = [
  'Registrant Name',
  'Checked In?',
  'Contact Email',
  'CRM ID',
  'Contact Phone',
]

export default function LiveAttendeeView(props) {
  const [showCheckInFilter, setShowCheckInFilter] = useState(true)
  const [showNotCheckedInFilter, setShowNotCheckedInFilter] = useState(true)
  const [nameFilter, setNameFilter] = useState('')
  const [auctionDetail, setAuctionDetail] = useState('')

  //array of truth for all registrations in auction
  const [allRegistrations, setAllRegistrations] = useState([])

  //filtered list based off selection
  const [displayedRegistrations, setDisplayedRegistrations] = useState([])

  // const { auctionId: auctionCode } = urlQueryParser(props.location.search)
  const auctionCode = props.auctionCode

  // load auction data by code, so it will work for deep-linking
  const { data: initialAuctionDetail, error, isLoading } = useQuery({
    queryKey: ["adminAuctionDetail", auctionCode],
    queryFn: async () => {
      return await adminGetAuctionByCode(auctionCode)
    },
    staleTime: 0
  })

  // poll for check in status every 15 seconds if auction is running
  useEffect(() => {
    let timer

    function clearTimer() {
      if (timer) {
        console.log('clearing timer')
        clearInterval(timer)
      }
    }

    async function refreshAuctionDetail() {
      if (auctionDetail && auctionCode) {
        console.log('will refresh auction detail')

        timer = setInterval(async () => {
          setAuctionDetail(await adminGetAuctionByCode(auctionCode))
          console.log('refreshing auction detail')
        }, 1000*15)
      }
      return
    }

    refreshAuctionDetail()

    return clearTimer

  }, [auctionDetail, auctionCode])

  //use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (initialAuctionDetail && initialAuctionDetail.error)) {
      console.log('Error!', error || initialAuctionDetail.error)
    }
    else {
      setAuctionDetail(initialAuctionDetail)
    }

  }, [initialAuctionDetail, error, isLoading])

  useEffect(() => {
    if (auctionDetail) setAllRegistrations(auctionDetail.Registrations)
  }, [auctionDetail])

  //filter for displayed attendees
  useEffect(() => {

    
    let filtered = [...allRegistrations]
    
    if (filtered.length === 0) return

    if (nameFilter) {
      filtered = allRegistrations?.filter((registration) => {
        console.log(registration)
        return (registration.User.FirstName.includes(nameFilter)) ||
          (registration.User.LastName.includes(nameFilter))
      })
    }
    
    const output = filtered.filter((registration) => {
      return (showCheckInFilter && registration.IsCheckedIn) || (showNotCheckedInFilter && !registration.IsCheckedIn)
    })

    setDisplayedRegistrations(output)

  }, [showNotCheckedInFilter, showCheckInFilter, allRegistrations, nameFilter])

  const resetFilters = () => {
    setShowCheckInFilter(true)
    setShowNotCheckedInFilter(true)
    setNameFilter('')
  }

  // make sure to update this if the columns in LiveAuctionRegistrationRow change
  const copyAttendeesToClipboard = () => {
    const textToCopy = [attendeeHeaders.map(headerName => headerName).join('\t')];
    displayedRegistrations.forEach(row => {
      textToCopy.push([
        formatName(row),
        row.IsCheckedIn ? 'Yes' : "No",
        row.User.ContactEmail ,
        "'"+row.User.CrmId,
        formatPhone(row.User.ContactPhone)
      ].join('\t'))
    })
    navigator.clipboard.writeText(textToCopy.join('\n'))
  }

  if (!auctionDetail) return (<></>)

  // console.log('auction detail', auctionDetail)
  // console.log('admin auction detail', auctionDetail)

  // console.log(displayedRegistrations)



  return (
    <div className={css['live-auction-container']}>
      
      <h2 id="landingPageTitle">
        Registrations / Attendees
      </h2>
      
      <button className={`${themeCss['button']} ${themeCss['FilledBlue']}`}>
        Go to Check-in App
      </button>
      
      <div className={css['live-auction-attendee-filters']}>
        <div className={css['live-auction-filter']}>
          <label>
            Attendee Name
          </label>
          <input type='text' value={nameFilter} onChange={(e) => setNameFilter(e.target.value)}/>
        </div>

        <div className={css['live-auction-filter']}>
          <div className={css['live-auction-filter-checkbox']}>
            <input type='checkbox' checked={showCheckInFilter} onChange={(e) => setShowCheckInFilter(!showCheckInFilter)} />
            <label> Show Checked in Users</label>
          </div>
          <div className={css['live-auction-filter-checkbox']}>
            <input type='checkbox' checked={showNotCheckedInFilter} onChange={(e) => setShowNotCheckedInFilter(!showNotCheckedInFilter)} />
            <label> Show Users Not Checked In</label>
          </div>
        </div>

        <button className={`${themeCss['button']} ${themeCss['FilledYellow']}`} onClick={resetFilters}>
          Reset Filters
        </button>
      </div>

      <div className={css['live-auction-table']}>
        <table>
          <thead>
            <tr className={css['live-auction-table-header']}>
              {attendeeHeaders.map(headerName => {
                return <th>{headerName}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {displayedRegistrations && displayedRegistrations?.map((registration) => {
            return <LiveAuctionRegistrationRow key={registration.RegistrationId} registration={registration} auctionCode={auctionCode} />
            })}
          </tbody>
          <tfoot>
            <tr className={css['live-auction-table-footer']}>
              
            </tr>
          </tfoot>
        </table>
      </div>
      
      <button className={`${themeCss['button']} ${themeCss['FilledYellow']}`} onClick={copyAttendeesToClipboard}>
        Copy List to Clipboard
      </button>

    </div>
  )
}