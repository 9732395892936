import React from 'react'

import css from './sass/landing.module.scss'

export default function GenericLanding() {

  return (
    
      <div className={css['landing-container']}>
        <p>
          Please follow the link from your invitation or confirmation to access the auction.
        </p>
        <p>
          If you have any further questions, please contact Auction Manager for QR code or link to your upcoming auction.
        </p>
      </div>
  )
}