import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from "@tanstack/react-query"


import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { formatMoneyCents } from 'services/util'

import { getAuctionByCode } from '../api'
import { urlQueryParser } from '../util/urlQueryParser'

import checkmark from '../components/images/check-circle.svg'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/checkout.module.scss'

import { useSelector } from "react-redux"
import { getAuctionUser } from '../redux/selectors'
import CheckoutHeader from './CheckoutHeader'
import { checkoutRouter } from './checkoutUtils'


export default function CheckoutSuccess(props) {
  //destructuring auction code from url
  //called id in url query for that convention, but using auction code in aws to declutter url from being massive from aws UUID for auction ID to link tables
  const { auctionId: auctionCode } = urlQueryParser(props.location.search)

  const [auction, setAuction] = useState()
  const [myItems, setMyItems] = useState()
  const [myTotal, setMyTotal] = useState()

  // probably get from auction details in the future?
  const feePercentage = 2

  const auctionUser = useSelector(getAuctionUser);

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["auctionDetail", auctionCode],
    queryFn: async () => {
      console.log('hello')
      return await getAuctionByCode(auctionCode)
    },
    staleTime: 0
  })
  // use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])


  useEffect(() => {
    if (auctionDetail) {
      setAuction(auctionDetail)
      const pulledItems = auctionDetail.Items.filter((item) => item.CurrentHighBidder === auctionUser.UserId)
      setMyItems(pulledItems)

    }
  }, [auctionDetail])
  
  useEffect(() => {
    if (myItems) {
      const total = myItems?.reduce(((acc, item) => acc += item.CurrentBid), 0)
      setMyTotal(formatMoneyCents(total))
    }

  }, [myItems])

  if (!auctionDetail || !myItems) return (<></>)
  
  return (
    <div className={css['checkout-container']}>

      <CheckoutHeader auctionCode={auctionDetail.AuctionCode} />
        
      <div className={css['checkout-success']}>
        <p>Payment Successful!</p>
        <p>${myTotal}</p>
        <img className={css['checkout-success-img']} src={checkmark} alt="check mark" />
      </div>

      <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${css['checkout-paybutton']}`}
          onClick ={() => checkoutRouter(auctionDetail.AuctionCode)}
          >Back to {localStorage.myItems ? 'My Items' : 'Auction'}
      </button>

    </div>
  )
}