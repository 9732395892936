import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getAuctionUser } from '../redux/selectors'
import { getAuctionUserApi } from '../api'
import { showLoading } from 'services/util'

import css from './sass/mypages.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import AuctionEndedCard from '../auctions/AuctionEndedCard'
import MyItemRow from './MyItemRow'

export default function MyItems() {
  const [pastAuctionList, setPastAuctionList] = useState([])
  const [stillOweAuctionList, setStillOweAuctionList] = useState([])
  const [allPaidAuctionList, setAllPaidAuctionList] = useState([])
  const [currentAuctionList, setCurrentAuctionList] = useState([])
  
  const auctionUser = useSelector(getAuctionUser)
  const currentUserId = auctionUser?.UserId

  useEffect(() => {
    async function refreshAuctionUser() {
      showLoading(true)
      // updates auctionUser in redux/localStorage
      await getAuctionUserApi()
      showLoading(false)
    }

    refreshAuctionUser()
  }, [])

  useEffect(() => {
    if (!auctionUser.MyAuctions)
      return

    const now = new Date()

    const currentAuctions = auctionUser.MyAuctions.map(obj => obj.Auction).filter(auction => auction.StartTime < now.toISOString() && auction.EndTime > now.toISOString())
    setCurrentAuctionList(currentAuctions)

    const upcomingAuctions = auctionUser.MyAuctions.map(obj => obj.Auction)
                                                   .sort((a, b) => (a.StartTime < b.StartTime) ? -1 : (a.StartTime > b.StartTime) ? 1 : 0)
                                                   .map(auction => { return {...auction, 'displayData': false}})

    const pastAuctions = upcomingAuctions.filter((auction) => auction.EndTime < now.toISOString())

    setPastAuctionList(pastAuctions)
  }, [auctionUser.MyAuctions])

  useEffect(() => {

    if (pastAuctionList) {
      const auctionItems = pastAuctionList.map((auction) => auction.Items)
      const auctionItemsPaid = pastAuctionList.map((auction) => auction.Items.every(item => item.Paid === true))
  
      console.log(auctionItems)
      console.log(auctionItemsPaid)
  
      console.log(pastAuctionList)

      let allPaid = []
      let stillOwe = []

      pastAuctionList.forEach((auction, index) => {
        if (auctionItemsPaid[index]) allPaid.push(auction)
        else stillOwe.push(auction)
      })

      setStillOweAuctionList(stillOwe)
      setAllPaidAuctionList(allPaid)

    }
  }, [pastAuctionList])

  return (
    <div>
      <div className={css['mypages-header']}>
        <h2 className={css['mypages-headings']}>My Items</h2>
        <p>Below are the items you have won, organized by auction.</p>
      </div>

      <div className={themeCss['blue-background']}>
        {stillOweAuctionList?.length > 0 && stillOweAuctionList.map((auction) => {
          return (
            <div className={css['mypages-card']}>
              <h3 className={css['mypages-headings']}>{auction.Title}</h3>
              <AuctionEndedCard auction={auction} currentUserId={currentUserId} />
              {auction?.Items?.length > 0 && auction?.Items?.map(item => <MyItemRow item={item} /> )}
            </div>
          )})}
          {allPaidAuctionList?.length > 0 && allPaidAuctionList.map((auction) => {
          return (
            <div>
              <h2 className={css['mypages-headings-white']}>
                Past Auctions
              </h2>
              <div className={css['mypages-card']}>
                <h3 className={css['mypages-headings']}>{auction.Title}</h3>
                <AuctionEndedCard auction={auction} currentUserId={currentUserId} />
                {auction?.Items?.length > 0 && auction?.Items?.map(item => <MyItemRow item={item} /> )}
              </div>
            </div>
          )})}
      </div>
    </div>
  )
}