import { useEffect, useState } from 'react'

import { showLoading } from 'services/util'

import css from './sass/adminitem.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import adminCss from './sass/adminpage.module.scss'

import ItemDeleteButton from './ItemDeleteButton'
import { getImageUrl } from '../util/images'
import ItemEdit from './ItemEdit'


export default function ItemDisplayRow( {item, auction, updateEditItemId, showEditItemModal, refreshAdminAuctionQuery} ) {

  const [imageUrl, setImageUrl] = useState()
  const [showEditModal, setShowEditModal] = useState(false)

  //set auction kind to silent for now - paddle to come later
  let kind = 'silent'

  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, item?.ItemCode, item?.ImageFilename)
    setImageUrl(url)
  }, [auction])
  

  return (
    <>
      <tr className={css['admin-item-row']}>
        <td>
          <img src={imageUrl} alt={item?.Title}/>
        </td>

        <td>{item?.Title}</td>
        <td>{item?.Value}</td>
        <td>${item?.StartingBid}</td>
        <td>${item?.MinRaise}</td>
        <td>{item?.ItemCode}</td>
        <td>{item?.Donor}</td>

        <td>
          <div className={css['admin-item-row-actions']}>
            <ItemDeleteButton item={item} refreshAdminAuctionQuery={refreshAdminAuctionQuery} />
            {/* <p>Duplicate</p> */}
            <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`} onClick={()=>updateEditItemId(item.ItemId)}
            >Edit</button>
          </div>
        </td>
      </tr>
    </>
  )
}