import { useState, useEffect } from 'react'

import img from '../auctions/images/banner-demo.jpg'
import { history } from 'services/history.js'

import { formatDate } from './userUtils'

import css from './sass/mypages.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import { Link } from 'react-router-dom'

export default function AuctionCardUpcoming({auction, isLive}) {

  return (
    <div className={css['mypages-card']}>
      {/* <img className={css['myauctions-image']} src={img}/> */}
      {isLive && <p className={css['myauctions-live']}>AUCTION IS LIVE</p>}
      <h2 className={`${css['mypages-headings']} ${css['myauctions-title']}`}>
        <Link className={css['card-image']} to={{ pathname: '/auctionhome', search: `?auctionId=${auction.AuctionCode}`}}>
          {auction.Title}
        </Link>
      </h2>
      <h3 className={css['mypages-headings']}>{formatDate(auction.StartTime)}</h3>
      <h3 className={css['mypages-headings']}>{auction.AuctionLocation}</h3>
      <p>{auction.Description}</p>
      
    </div>
  )
}