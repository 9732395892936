import { useEffect, useState, useRef } from 'react'
import { useSelector } from "react-redux"

import store from 'redux/store'
import { setLiveAuctionCode } from '../../redux/actions'

import { isAttending } from '../../util/userUtils'
import adminCss from '../sass/adminpage.module.scss'


import { getAuctionUser } from '../../redux/selectors'
import TestAuctionButtons from './TestAuctionButtons'

export default function TestFrameAuction(props) {
  const auctionDetail = props.auctionDetail
  const auctionCode = auctionDetail?.AuctionCode

  const [auctionState, setAuctionState] = useState()
  //for iframe updates
  const iframeRef = useRef()

  //for iframeRef
  const sendActionToIframe = (action) => {
    if (iframeRef.current) {
        iframeRef.current.contentWindow.postMessage({ action }, 'http://localhost:3000'); // Use the correct domain
    }
  }

  // redux liveAuctionCode is consumed by <OutBidNotification> set in the theme header (and could be by other global widgets)
  // I think it's best to only set auctionCode and force the widget to get a fresh version of the auction (since some things can change)
  // system assumes only one live auction at a time, which I think is a fair assumption to not add tons of code to handle multiple live auctions
  useEffect(() => {
    if (auctionState === 'started') {
      store.dispatch(setLiveAuctionCode(auctionCode))
    }
    else {
      store.dispatch(setLiveAuctionCode())
    }
   }, [auctionState, auctionCode])

  // 284 462

  // maybe provide a link for if no auction is loaded to reroute to load auctions - 404 something
  if (!auctionDetail) return (<></>)
  
  return (
  <div className={adminCss['test-auction-page']}>
    <iframe title="Inline Frame Example"
      ref={iframeRef}
      width="675" height="667"
      src={`/flow/auction?auctionId=${auctionCode}`}
      className={adminCss['test-auction-iframe']}
    >

    </iframe>

    <TestAuctionButtons sendActionToIframe={sendActionToIframe} />
  </div>
  )
}