import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { showLoading } from 'services/util'
import { history } from 'services/history.js'

import { urlQueryParser } from '../util/urlQueryParser'
import { getAuctionStatus } from '../util/dateString'

import { adminGetAuctionByCode } from './auctionsadminutil'

import adminCss from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import TabNav from 'sites/auctions/components/TabNav'
import AdminAuctionDetail from './AdminAuctionDetail'
import AdminAuctionItems from './AdminAuctionItems'
import PreviewPublish from './PreviewPublish'
import AuctionDetailsAlert from './AuctionDetailsAlert'
import AdminBreadcrumbs from './AdminBreadcrumbs'
import ClosedBidding from './ClosedAuction/ClosedBidding'
import LiveAttendeeView from './UserDetail/LiveAttendeeView'
import LiveAuctionView from './UserDetail/LiveAuctionView'
import PreBidding from './UserDetail/PreBidding'
import PreAttendees from './UserDetail/PreAttendees'
 
// getAuctionStatus = (currentTime, startTime, endTime) => {
export default function AdminAuctionTabs(props) {
  
  const [tabTitles, setTabTitles] = useState([])
  const [tabPanels, setTabPanels] = useState([])
  const [goToIndex, setGoToIndex] = useState(0)

  //to refresh query on changes from the Status update
  const queryClient = useQueryClient()

  const { auctionId: auctionCode } = urlQueryParser(props.location.search)

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["adminAuctionTabDetail", auctionCode],
    queryFn: async () => {
      return await adminGetAuctionByCode(auctionCode)
    },
    staleTime: 0
  })
  
  const currentTime = new Date()
  const liveStatus = getAuctionStatus(currentTime, auctionDetail?.StartTime, auctionDetail?.EndTime)

  const refreshAdminAuctionQuery = () => {
    queryClient.invalidateQueries(["adminAuctionTabDetail"])
  }

  useEffect(() => {
    switch(liveStatus) {
      case "ended":
        setTabTitles(['Auction Details', 'Auction Items', 'View Bidding', 'View Attendees'])
        setTabPanels([<AdminAuctionDetail history={props.history} auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} setGoToIndex={setGoToIndex} />,
                      <AdminAuctionItems auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} />,
                      <ClosedBidding auctionCode={auctionCode} />,
                      <LiveAttendeeView auctionCode={auctionCode} />])    
                      
        break;
      case "started":
        setTabTitles(['Auction Details', 'Auction Items', 'View Bidding', 'View Attendees'])
        setTabPanels([<AdminAuctionDetail history={props.history} auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} setGoToIndex={setGoToIndex} />,
                      <AdminAuctionItems auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} />,
                      <LiveAuctionView auctionCode={auctionCode} />,
                      <LiveAttendeeView auctionCode={auctionCode} />])
        break;
      case "before":
        setTabTitles(['Auction Details', 'Auction Items', 'View Bidding', 'View Attendees'])
        setTabPanels([<AdminAuctionDetail history={props.history} auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} setGoToIndex={setGoToIndex} />,
                      <AdminAuctionItems auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} />,
                      <PreBidding />,
                      <PreAttendees />])
        break;
      default:
        setTabTitles(['Auction Details', 'Auction Items', 'View Bidding', 'View Attendees'])
        setTabPanels([<AdminAuctionDetail history={props.history} auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} setGoToIndex={setGoToIndex} />,
                      <AdminAuctionItems auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} />,
                      <PreBidding />,
                      <PreAttendees />])
      }

  }, [liveStatus, auctionDetail, auctionCode])

  //use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }
  }, [auctionDetail, error, isLoading])

  return (
    <div className={adminCss['admin-auction-page-container']}>
      <AdminBreadcrumbs auctionDetail={auctionDetail} liveStatus={liveStatus} />
      <div className={adminCss['admin-auction-page-header']}>
        {/* <h1>{auctionDetail?.Title}</h1> */}
        <a
          onClick ={(e) => {history.push({
            pathname: '/auctionhome',
            search: `?auctionId=${auctionDetail.AuctionCode}`,
            // auctionDetail: auction
          })}}
          className={adminCss['admin-auction-page-header-link']}
        >{auctionDetail?.Title}</a>
        <PreviewPublish auctionDetail={auctionDetail} liveStatus={liveStatus} refreshAdminAuctionQuery={refreshAdminAuctionQuery} />
      </div>
      <AuctionDetailsAlert liveStatus={liveStatus} />
      <div className={adminCss['admin-auction-page-tabnav']} >
        <TabNav titles={tabTitles} panels={tabPanels} goToIndex={goToIndex} />
      </div>
    </div>
  )
}