import { useState, useEffect } from 'react'

import FileDropzone from 'admin/FileDropzone'
import { showLoading } from 'services/util'

import Alert from 'common/notifications/Alert'
import { FilledYellow } from 'css/Buttons'
import iconInfo from 'images/iconColorinfo.svg'
import success from 'images/TickMark.svg'
import * as colors from 'css/Colors'
import css from './sass/upload.documents.module.scss'

import { adminGetAuctionImagePresignedUrl } from "./auctionsadminutil"
import { updateAuctionItemMutation, updateAuctionMutation } from '../graphql/Mutations'
import { useMutation } from '@tanstack/react-query'
import { graphql } from '../api'

const successPrefix = "All files uploaded successfully:"

function UploadFiles (props) {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [dropzoneKey, setDropzoneKey] = useState(0)
  const [submitError, setSubmitError] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState('')
  const [displayThumbnail, setDisplayThumbnail] = useState()

  const auctionId = props.auction?.AuctionId
  const auctionCode = props.auction?.AuctionCode
  const itemId = props.item?.ItemId
  const itemCode = props.item?.ItemCode

  useEffect(() => {
    if (selectedFiles.length === 0) {
      setSubmitError('')
    }
    else if (selectedFiles.length > 0) {
      setSubmitError(validateFilenames(selectedFiles))
      setSubmitSuccess('')
    }
  }, [selectedFiles])

  useEffect(() => {
    setSubmitError('')
    setSubmitSuccess('')
  }, [])

  //updates to database after image uploads
  const {data: updateAuctionResponse, updateAuctionError, updateAuctionPending, mutate: saveAuction } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateAuctionMutation, variables: {input: input} })
    },
  })

  //updates to database after image uploads
  const {data: updateAuctionItemResponse, updateAuctionItemError, updateAuctionItemPending, mutate: saveItem } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateAuctionItemMutation, variables: {input: input} })
    },
  })

  const auctionUpdate = async () => {
    const input = {
        AuctionId: auctionId,
        BannerImageName: selectedFiles[0]?.path,
      }
      saveAuction(input)
  }

  //use effect for auction updating error
  useEffect(() => {

    if (updateAuctionError) {
      console.log("Auction not created: ", updateAuctionError)
      console.log(updateAuctionError.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (updateAuctionResponse) {
      console.log(updateAuctionResponse)
      console.log('Auction updated: ', updateAuctionResponse.data.updateAuction.AuctionId)
      props.refreshQuery()
    }
  }, [updateAuctionResponse, updateAuctionError, updateAuctionPending, props.refreshQuery])

  const itemUpdate = async () => {
    const input = {
      ItemId: itemId,
      ImageFilename: selectedFiles[0]?.path,
      }

      saveItem(input)
      props.refreshQuery()
  }

  //use effect for auction Item updating error
  useEffect(() => {

    if (updateAuctionItemError) {
      console.log("Auction not created: ", updateAuctionItemError)
      console.log(updateAuctionItemError.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (updateAuctionItemResponse) {
      console.log(updateAuctionItemResponse)
      console.log('Auction updated: ', updateAuctionItemResponse.data.updateAuctionItem.AuctionId)
      props.refreshQuery()
    }
  }, [updateAuctionItemResponse, updateAuctionItemError, updateAuctionItemPending, props.refreshQuery])

  const validateImageFile = (file, errors) => {
    console.log({file, errors})
    const accepted = ['image/jpeg', 'image/png']

    if (accepted.indexOf(file.type) === -1) {
      errors.push({file, msg: "Filename has too many sections"})
    }
  }

  const validateFilenames = (files, docType) => {
    const errors = []
    const file = files[0]

    validateImageFile(file, errors)

    return (errors.length === 0) ? '' : (
      <>
        <p>Filetype is not allowed. Please select a file that is *.png, *.jpeg, or *.jpg and try again:</p>
        <ul>
          {errors.map((error, index) => (
            <li key={index}><b>{error.msg}: {error.file.name}</b></li>
          ))}
        </ul>
      </>
    )
  }

  const doSubmitSuccess = (files) => (
    <>
      <b>{successPrefix}</b>
      <ul>
        {files.map((file, index) => (<li key={index}>{file.name}</li>))}
      </ul>
    </>
  )

  const uploadToS3 = async (file, contentType, presignedUrl) => {
    const fetchData = {
      method: 'PUT',
      headers: {
        'Content-Type': contentType,
      },
      body: file
    }

    try {
      const response = await fetch(presignedUrl, fetchData)
      return response
    }
    catch (e) {
      console.log(e)
    }
  }

  const onClear = async e => {
    setSelectedFiles([])
    setDropzoneKey(dropzoneKey + 1) // hack because dropzone doesn't expose a way to reset itself
  }

  const onSubmit = async e => {
    console.log('what')

    e.preventDefault()
    
    console.log('fake submission')
    if (validateFilenames(selectedFiles))
      return

    showLoading(true)
    setDisplayThumbnail()

    let submitAuction = 'aaa'
    let submitItem = 'iii'

    switch(props.imageType) {
        case "auction":
          submitAuction = auctionCode
          submitItem = 'banner'
          break;
        case "item":
          submitAuction = auctionCode
          submitItem = itemCode
          break;
        default:
          break;
    }


    const uploadResponses = []
    const uploadErrors = []
    for (const file of selectedFiles) {
      const presignedUrl = await adminGetAuctionImagePresignedUrl( submitAuction,  submitItem, file.name, file.type)
      console.log({presignedUrl})
      const response = await uploadToS3(file, file.type, presignedUrl)
      if (response && response.ok && response.status === 200) {
        uploadResponses.push(response)
        // set to always hide the new display thumbnail auction edit page will refresh when updated
        if (props.pageType === 'editAuction') setDisplayThumbnail()
        // otherwise set the display thumbnail
        else setDisplayThumbnail({auctionId: submitAuction, itemId: submitItem, fileName:file.name})
      }
      else {
        uploadErrors.push({ fileName: file.name, res: (response || {statusCode:'', statusText:'No response'}) })
      }

    }

    if (uploadErrors.length > 0) {
      setSubmitError(doUploadError(uploadErrors))
      showLoading(false)
      return
    }

    setDropzoneKey(dropzoneKey + 1) // hack because dropzone doesn't expose a way to reset itself

    if (props.imageType === 'auction') auctionUpdate()
    if (props.imageType ==='item') itemUpdate()

    showLoading(false)
  }

  const doUploadError = (uploadErrors) => (
    <>
      <p>There was an error uploading some of your files</p>
      <ul>
        {uploadErrors.map((error, index) => (
          <li key={index}>{error.fileName}: {error.res.statusText} {error.res.status} </li>
        ))}
      </ul>
    </>
  )

  // For localhost we need to change domain to pilot
  const getImgSrc = (displayThumbnail) => {
    let origin = window.location.origin

    if (origin === "http://localhost:3000")
      origin = "https://pilot.auctions.support2.ucla.edu"

    return `${origin}/auctions/images/${displayThumbnail.auctionId}/${displayThumbnail.itemId}%7C${displayThumbnail.fileName}`
  }

  const doProcessError = (processResponse) => (
    <>
      <p>There was an error processing some of your files</p>

      {!processResponse.saveFileSuccess &&
        <div>Debug info: {JSON.stringify(processResponse,0,2)}</div>}

      {processResponse.saveFileError && processResponse.saveFileError.length > 0 &&
        <ul>
          {processResponse.saveFileError.map((error, index) => (
            <>
              {error.saveFileResponse && error.saveFileResponse.Error &&
                <li key={index}>File Save to DB Error: {error.saveFileResponse.Error.message}</li>}

              {error.processFileResponse && error.processFileResponse.Error &&
                <li key={index*-1}>File Process to S3 Error: {error.processFileResponse.Error.message}</li>}
            </>
          ))}
        </ul>}

      {processResponse.saveFileSuccess && (!processResponse.saveFileError || processResponse.saveFileError.length === 0) &&
        <div>File Save Debug Info: {JSON.stringify(processResponse.saveFileSuccess,0,2)}</div>}
    </>
  )

  return (
    <div className={css.formStyles}>

      <p>Files should be one of the following image types: .jpg, .jpeg, .png</p>

      {submitSuccess &&
        <Alert
          nonPromoPage
          icon={success}
          color={colors.guideGreen}
          width="20" height="15" top="40%" >
          <div>{submitSuccess}</div>
        </Alert>
      }

      {displayThumbnail &&
        <img alt="thumbnail" className={css['uploadedImage']} src={getImgSrc(displayThumbnail)} />
      }

      <form>

        <FileDropzone
          key={'dropzone-' + dropzoneKey}
          setSelectedFiles={setSelectedFiles}
        />

        {submitError &&
          <Alert icon={iconInfo} >
            <div>{submitError}</div>
          </Alert>
        }

        <FilledYellow type="submit" onClick={onSubmit} disabled={selectedFiles.length === 0 || submitError}> Upload </FilledYellow>
        <FilledYellow onClick={onClear} disabled={selectedFiles.length === 0}> Clear </FilledYellow>
      </form>
    </div>
  )
}

export default UploadFiles
