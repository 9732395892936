import { useState, useEffect } from 'react'

import { showLoading } from 'services/util'

import infoIcon from '../../components/images/info-icon-orange.svg'

import css from './sass/confirmmodal.module.scss'
import themeCss from '../../components/sass/theme.module.scss'
import NumberRow from './NumberRow'
import LabeledData from './LabeledData'

export default function AlertWithData({modalUse, data}) {

  const [alertHeader, setAlertHeader] = useState('')

  const [chargeOption, setChargeOption] = useState('full')

  useEffect(() => {

    if (modalUse) {

      switch (modalUse) {
        case 'confirmCharges':
            setAlertHeader('CONFIRM CHARGES')
            break;
        case 'markingItemsAsPaid':
            setAlertHeader('CONFIRM MARKING ITEMS AS PAID')
            break;
        case 'itemCharge':
            setAlertHeader('CONFIRM ITEM CHARGE')
            break;
        case 'modifyItemAmountDue':
            setAlertHeader('MODIFY ITEM AMOUNT DUE')
            break;
        case 'paymentReminderEmail':
            setAlertHeader('SEND PAYMENT REMINDER EMAIL')
            break;
        default:
            setAlertHeader('')
      }
    }

  }, [modalUse])


  return (
    <div className={css['withdata-body']}>
      <div className={css['withdata-text']}>
        <h3>{alertHeader}</h3>
        {data.itemsCharged && <>
          <NumberRow number={data.itemsCharged} dollar={false} text={'ITEMS BEING CHARGED'} />
          <NumberRow number={data.totalCharged} dollar={true} text={'TOTAL CHARGES'} />
        </>}
        {data.itemsMarkedPaid && <>
          <NumberRow number={data.itemsMarkedPaid} dollar={false} text={'ITEMS BEING MARKED AS PAID'} />
          <NumberRow number={data.totalMarkedPaid} dollar={true} text={'TOTAL BEING MARKED AS PAID'} />
        </>}
        {data.itemName && <>
          <LabeledData label={'Item'} data={data.itemName} />
          <LabeledData label={'Attendee'} data={data.attendeeName} />
          <NumberRow number={data.amountDue} dollar={true} text={'DUE FOR ITEM'} />
        </>}
        {modalUse === 'itemCharge' && <>
          <div className={css['customcharge-radio-container']}>
            <label>
              <input
                type="radio"
                value="full"
                className={css['customcharge-radio-buttons']}
                checked={chargeOption === 'full'}
                onChange={() => setChargeOption('full')}
              />
              Charge full amount
            </label>
            <label>
              <input
                type="radio"
                value="custom"
                className={css['customcharge-radio-buttons']}
                checked={chargeOption === 'custom'}
                onChange={() => setChargeOption('custom')}
              />
              Charge custom amount
            </label>
          </div>
          <div className={css['customcharge-input-container']}>
            <label>Custom Amount to Charge</label>
            <p className={css['customcharge-input-dollar']}>$</p>
            <input type='number' placeholder={'Placeholder'} disabled={chargeOption !== 'custom'}/>
            <p className={css['customcharge-info-text']}>Custom amount paid will count this item as paid-in-full</p>
          </div>
        </>}
        {modalUse === 'modifyItemAmountDue' && <div className={css['customcharge-input-container']}>
          <label>New Amount Due</label>
          <p className={css['customcharge-input-dollar']}>$</p>
          <input type='number' placeholder={'Placeholder'} />
        </div>}
        {data.attendeeCount && <>
          <p>SEND REMINDER EMAIL TO</p>
          <NumberRow number={data.attendeeCount} dollar={false} text={'ATTENDEES'} />
        </>}
        
      </div> 
    </div>
  )
}