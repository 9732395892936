import { API } from 'aws-amplify'

import store from 'redux/store'
import { doApi } from 'services/api'
import { capitalize } from 'services/util';

import { listAllAuctionsQuery, listUpcomingAuctionsQuery, getAuctionByIdQuery, getAuctionUserQuery, getAuctionByCodeQuery } from './graphql/Queries'
import { createUserMutation, updateUserMutation, updateCurrentBidMutation, registerForAuctionMutation, unregisterFromAuctionMutation, updateUserRegistrationMutation } from './graphql/Mutations'
import { setAuctionUser } from './redux/actions'
import { getAuctionUser } from './redux/selectors'

API.configure({
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AUCTIONS_APPSYNC_HOST,
  // "aws_appsync_graphqlEndpoint": "https://6dbbpqxk5bgmjnd6q6e7mucqba.appsync-api.us-west-2.amazonaws.com/graphql",
  "aws_appsync_region": "us-west-2",
  // "aws_appsync_authenticationType": "API_KEY",
  // "aws_appsync_apiKey": "da2-gep6wqtz3jgndmup5ndujw6sl4",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
})

export const graphql = async (args) => {
  return await API.graphql(args)
}

export const subscribe = (args, actions) => {

  return API.graphql(args).subscribe(actions)
}

export const registerUserForAuction = async (auctionCode, auctionUser, textAlertNumber) => {
  const auction = await getAuctionByCode(auctionCode)

  const signUpResponse = await graphql({query: registerForAuctionMutation, variables: {auctionId: auction.AuctionId, textAlertNumber: textAlertNumber || ""}})
  if (signUpResponse?.data?.registerUser?.RegistrationId) {
    const MyAuctions = auctionUser.MyAuctions.concat([{Auction: {...auction}, QrBase64: signUpResponse.data.registerUser.QrBase64, RegistrationId: signUpResponse.data.registerUser.RegistrationId}])
    store.dispatch(setAuctionUser({ auctionUser: {...auctionUser, MyAuctions} }));
  }
  return signUpResponse
}
export const unregisterUser = async (auction, auctionUser) => {
  const unregisterResponse = await graphql({query: unregisterFromAuctionMutation, variables: {registrationId: auction.RegistrationId}})
  if (unregisterResponse?.data?.unregisterUser?.RegistrationId) {
    const MyAuctions = auctionUser.MyAuctions.filter(obj => obj.Auction.AuctionId !== auction.AuctionId)
    store.dispatch(setAuctionUser({ auctionUser: {...auctionUser, MyAuctions} }))
  }
}

export const updateUserRegistration = async (auction, auctionUser, properties) => {
  const updateUserRegistrationResponse = await graphql({query: updateUserRegistrationMutation, variables: {auctionId: auction.AuctionId, ...properties}})
  const updatedAuction = updateUserRegistrationResponse?.data?.updateUserRegistration

  if (updatedAuction) {
    const workingAuction = auctionUser.MyAuctions.filter(userAuction => {
      console.log({userAuction, auction})
      return userAuction.Auction.AuctionCode === auction.AuctionCode
    })[0]

    if (workingAuction) {
      Object.keys(properties).forEach(propKey => {
        workingAuction[capitalize(propKey)] = properties[propKey]
      })
      store.dispatch(setAuctionUser({ auctionUser: {...auctionUser, MyAuctions: auctionUser.MyAuctions} }));
    }
  }
  return updateUserRegistrationResponse
}

// retrieves all upcoming auctions, can be used to get user's auctions or a list of upcoming auctions
export const getUpcomingAuctions = async () => {
  console.log('getUpcomingAuctions!')

  const query = localStorage.isSeleniumTest ? listUpcomingAuctionsQuery.replace('EndTime', 'IsForTestOnly: {eq: true}, EndTime') : listUpcomingAuctionsQuery

  const response = await graphql({query})
  if (!response?.data?.listAuctions?.items) {
    console.error("error: ", response)
  }
  else {
    // filter out planned gift and recurring gift payments
    const auctionList = response.data.listAuctions.items
    // store.dispatch(setUpcomingAuctionList({auctionList}))
    // auctionList.forEach(auction => store.dispatch(setSavedAuction({auction})))
    // localStorage.setItem('auctionList', JSON.stringify(auctionList))
    return auctionList
  }
}

// retrieves all auctions (before 1/1/2023)
export const getAllUpcomingAuctions = async () => {
  console.log('getAllAuctions!')

  const query = localStorage.isSeleniumTest ? listAllAuctionsQuery.replace('EndTime', 'IsForTestOnly: {eq: true}, EndTime') : listAllAuctionsQuery
  const response = await graphql({query})

  if (!response?.data?.listAuctions?.items) {
    console.error("error: ", response)
  }
  else {
    const auctionList = response.data.listAuctions.items
    return auctionList
  }
}

// retrieves items and full details for a given auction, saves details to local storage if not already saved
export const getAuctionDetail = async (auctionId) => {
  console.log('getAuctionDetail!')

  const auctionList = (localStorage.upcomingAuctionList) ? JSON.parse(localStorage.upcomingAuctionList) : await getUpcomingAuctions()

  const auction = auctionList.filter(auction => auction.AuctionId === auctionId)[0]

  if (!auction) {
    return {error: "no auction found"} // in the future this might make one attempt to re-fetch the auction list
  }
  else if (auction.Items) { // if Items is populated, we assume auction detail has been called
    return auction
  }
  else {
    const response = await graphql({query: getAuctionByIdQuery, variables: {auctionId}})
    if (!response?.data?.getAuction) {
      return({"error": response})
    }
    else {
      auctionList[auctionList.findIndex(obj => obj.AuctionId === auctionId)] = response.data.getAuction
      // localStorage.setItem('upcomingAuctionList', JSON.stringify(upcomingAuctionList))
      return response.data.getAuction
    }
  }
}

// retrieves items and full details for a given auction, saves details to local storage if not already saved
export const getAuctionByCode = async (auctionCode) => {
  console.log('getAuctionByCode!')

  const response = await graphql({query: getAuctionByCodeQuery, variables: {auctionCode}})
  if (!response?.data?.getAuctionByCode) {
    console.log(response)
    return({"error": response})
  }
  else {
    console.log(response)
    return response.data.getAuctionByCode
  }
}

// goes outside of AppSync and GraphQL
export const getPublicAuctionDetail = async (auctionCode, publicId, email) => {
  console.log('getAuctionByCode!')
  return await doApi({noAuth: true, route:`nonauthauctiondetails?code=${auctionCode}&pid=${publicId}&email=${email}`})
}

// always goes to the DB for fresh auction data
export const getLiveAuctionData = async (auctionId) => {
  console.log('getAuctionDetail!')

  const response = await graphql({query: getAuctionByIdQuery, variables: {auctionId}})
  if (!response?.data?.getAuction) {
    return({"error": response})
  }
  else {
    return response.data.getAuction
  }
}

// retrieves user with all auctions user is signed up for (should be called after login)
export const getAuctionUserApi = async () => {
  console.log('getAuctionUserApi!')
  try {
    const response = await graphql({query: getAuctionUserQuery})
    if (response?.data?.getUser === null) {
      console.log("No user found: ", response)
      return null
    }
    else if (!response?.data?.getUser) {
      console.error("bad response in getAuctionUserQuery: ", response)
      return {error: {msg: "bad response in getAuctionUserQuery: " + JSON.stringify(response)}}
    }
    else {
      const auctionUser = response.data.getUser
      console.log({auctionUser})

      // filter only items user has won or is winning
      auctionUser.MyAuctions.forEach(auction => auction.Auction.Items = auction.Auction.Items.filter(item => item.CurrentHighBidder === auctionUser.UserId))

      store.dispatch(setAuctionUser({auctionUser}))
      return auctionUser
    }
  }
  catch (e) {
    console.error("error in getAuctionUserQuery", e)
    const msg = (e.errors && e.errors.length > 0) ? e.errors[0].message : e
    return {error: {msg: "error in getAuctionUserQuery: " + msg}}
  }
}

// Creates Auction User with new entries in auctions-cognito-login-mapping and auctions-users
export const createAuctionUser = async () => {
  console.log('createAuctionUser!')
    try {
      const response = await graphql({query: createUserMutation})
      if (!response?.data?.createUser?.UserId) {
        console.error("User not created: ", response)
      }
      else {
        const auctionUser = {UserId: response.data.createUser.UserId, MyAuctions: []}
        store.dispatch(setAuctionUser({auctionUser}))
        return auctionUser
      }
    }
    catch (e) {
      console.error("error in createAuctionUser:", e)
    }
}

// Creates Auction User with new entries in auctions-cognito-login-mapping and auctions-users
export const updateAuctionUser = async (userFields) => {
  console.log('updateAuctionUser!')
    try {
      const response = await graphql({query: updateUserMutation, variables: userFields })
      if (!response?.data?.updateUser?.UserId) {
        console.error("User not updated: ", response)
        return {error: {response}}
      }
      else {
        const updatedUser = response.data.updateUser
        const savedUser = getAuctionUser(store.getState())
        Object.assign(savedUser, updatedUser) // TODO - this probably isn't robust enough
        store.dispatch(setAuctionUser({auctionUser: savedUser}))
        return savedUser
      }
    }
    catch (e) {
      console.error("error in updateAuctionUser:", e)
      return {error: {e}}
    }
}