import { useEffect } from 'react'
import { useQuery } from "@tanstack/react-query"
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { showLoading } from 'services/util'

import { urlQueryParser } from '../../util/urlQueryParser'
import { getAuctionStatus } from '../../util/dateString'

import { adminGetAuctionByCode } from '../auctionsadminutil'

import adminCss from '../sass/adminpage.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

import AdminBreadcrumbs from '../AdminBreadcrumbs'
import TestFrameAuction from './TestFrameAuction'
 
export default function AdminTestAuctionPage(props) {

  const { auctionId: auctionCode } = urlQueryParser(props.location.search)
  
  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["adminAuctionDetail", auctionCode],
    queryFn: async () => {
      return await adminGetAuctionByCode(auctionCode)
    },
    staleTime: 0
  })
  
  const currentTime = new Date();

  const liveStatus = getAuctionStatus(currentTime, auctionDetail?.StartTime, auctionDetail?.EndTime)

  //use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }
  }, [auctionDetail, error, isLoading])

  return (
    <div className={adminCss['admin-auction-page']}>
      <AdminBreadcrumbs auctionDetail={auctionDetail} liveStatus={liveStatus} />
      <div className={adminCss['admin-auction-page-header']}>
        <h1>{auctionDetail?.Title}</h1>
        
        <Link className={`${themeCss['button']} ${themeCss['FilledBlue']}`}
          to={`/admin/auctiondetail?auctionId=${auctionCode}`}>
          Go to Auction Details and Items
        </Link>

        <Link className={`${themeCss['button']} ${themeCss['FilledBlue']}`}
          to={`/admin/userdetail?auctionId=${auctionCode}`}>
          Go to Bidding and Attendees
        </Link>
      </div>
      
      <TestFrameAuction auctionDetail={auctionDetail} />
    </div>
  )
}