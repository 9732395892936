import { useState, useEffect } from 'react'

import { showLoading } from 'services/util'

import css from './sass/confirmmodal.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

import AlertWithData from './AlertWithData'
import AlertSimple from './AlertSimple'

export default function ConfirmModal({showConfirmModal, setShowConfirmModal, modalUse, data}) {

  const [confirmText, setConfirmText] = useState('')
  const [isSimple, setIsSimple] = useState(false)

  const toggleModal = () => {
    setShowConfirmModal(false)
  }

  const performConfirm = () => {

  }

  useEffect(() => {

    if (modalUse) {

      switch (modalUse) {
        case 'endBidding':
            setConfirmText('End Live Bidding')
            setIsSimple(true)
            break;
        case 'closeAuction':
            setConfirmText('Close Auction Site')
            setIsSimple(true)
            break;
        case 'confirmCharges':
            setConfirmText('Submit Charges for Processing')
            setIsSimple(false)
            break;
        case 'markingItemsAsPaid':
            setConfirmText('Confirm Mark as Paid')
            setIsSimple(false)
            break;
        case 'itemCharge':
            setConfirmText('Submit Charge for Processing')
            setIsSimple(false)
            break;
        case 'modifyItemAmountDue':
            setConfirmText('Save New Amount Due')
            setIsSimple(false)
            break;
        case 'paymentReminderEmail':
            setConfirmText('Send Reminder Email(s)')
            setIsSimple(false)
            break;
        default:
            setConfirmText('Error')
            setIsSimple(true)
    }
    }
  }, [modalUse])


  return (
    <div className={css['modal-container']}>
      <div className={themeCss['modal-background']} onClick={()=>toggleModal()}> </div>
      <div className={`${css['modal-content']} ${isSimple ? css['top-orange-border'] : ''}`}>
        {isSimple ? <AlertSimple modalUse={modalUse} /> : ''}
        {!isSimple ? <AlertWithData modalUse={modalUse} data={data} /> : ''}
        <div className={css['button-options']}>
          <button className={`${themeCss['button']} ${themeCss['FilledWhite']} ${themeCss['centered-button']}`}
              onClick={()=>toggleModal()} >Cancel</button>
          
          <button className={`${themeCss['FilledBlue']} ${themeCss['button']} ${themeCss['centered-button']} ${css['modal-buttontext-fixing']}`} 
            onClick={()=> performConfirm()}>{confirmText}</button>
        </div>
      </div>    
    </div>
  )
}