import { useState, useEffect } from 'react'

import { formatMoneyCents } from 'services/util'

import { showLoading } from 'services/util'

import css from './sass/confirmmodal.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function LabeledData({label, data}) {

  return (
    <div className={css['labeled']}>
      <h4>{label}</h4>
      <p>{data}</p>
    </div>
  )
}