import { useState, useEffect } from 'react'
import { useMutation } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { graphql } from '../api'
import { updateAuctionMutation } from '../graphql/Mutations'

import themeCss from '../components/sass/theme.module.scss'
import { getAuctionStatus } from '../util/dateString'

export default function UpdateAuctionStatusButton({ auction, fromStatus, toStatus, refreshAdminAuctionQuery}) {

  const [buttonText, setButtonText] = useState('Update Status')
  
  const {data: updateAuctionResponse, error, isPending, mutate: saveAuction } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateAuctionMutation, variables: {input: input} })
    },
  })

  useEffect(() => {
    showLoading(isPending)

    if (error) {
      console.log("Auction not created: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (updateAuctionResponse) {
      console.log(updateAuctionResponse)
      console.log('Auction updated: ', updateAuctionResponse.data.updateAuction.AuctionId)
      //invalidate query to refresh the page from AdminAuctionTabs.js
      refreshAdminAuctionQuery()
    }
  }, [updateAuctionResponse, error, isPending, refreshAdminAuctionQuery])

  const doUpdate = async () => {
    

    const input = {
        AuctionId: auction.AuctionId
      }

      if (toStatus !== 'ENDED') {
        input['Status'] = toStatus
      } else {
        let now = new Date()

        input['EndTime'] = now.toISOString()
      }

      console.log(input)
    
      saveAuction(input)
  }

  useEffect(() => {
    switch(fromStatus) {
      case "PREVIEW":
        setButtonText('Make Auction Public')
        break;
      case "PUBLIC":
        if (toStatus === 'ENDED') setButtonText('End Bidding')
        if (toStatus === 'CLOSED') setButtonText('Close Auction')
        if (toStatus === 'PREVIEW') setButtonText('Set To Preview')
        break;
      case "CLOSED":
        setButtonText('TESTING ONLY TO PUBLIC')
        break;
      default:
        setButtonText('Error Loading State')
    }
  }, [toStatus, fromStatus])

  return (
    <button className={`${themeCss['FilledBlue']} ${themeCss['button']}`} onClick={() => doUpdate()}>
      {buttonText}
    </button>
  )
}