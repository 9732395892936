import { useState, useEffect } from 'react'

import { showLoading } from 'services/util'

import infoIcon from '../../components/images/info-icon-orange.svg'

import css from './sass/confirmmodal.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function AlertSimple({modalUse}) {

  const [alertHeader, setAlertHeader] = useState('')
  const [alertMessage, setAlertMessage] = useState('')


  useEffect(() => {

    if (modalUse === 'endBidding') {
      setAlertHeader('Are you sure you want to end live bidding?')
      setAlertMessage('Bidding results will be saves as is. This action cannot be undone.')
    }
    if (modalUse === 'closeAuction') {
      setAlertHeader('Are you sure you want to close this auction site?')
      setAlertMessage('Attendees will no longer be able to access the site for this auction. This action cannot be undone.')
    }
  }, [modalUse])


  return (
    <div className={css['simple-body']}>
      <img src={infoIcon} />
      <div className={css['simple-text']}>
        <h3>{alertHeader}</h3>
        <p>{alertMessage}</p>
      </div> 
    </div>
  )
}