import { useState, useEffect } from 'react'

import { history } from 'services/history.js'

import css from './sass/mypages.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import { formatDate } from './userUtils'

export default function AuctionRowPast( {auction}) {

  return (
    <div className={themeCss['box-left-yellow-accent-content']}>
      <div className={css['myauctions-row']}>
        <p className={`${css['mypages-headings']} ${css['myauctions-title']}`}>{auction.Title}</p>
        <p className={css['myauctions-row-endtext']}>Ended: {formatDate(auction.EndTime)}</p>

      </div>
    
    
    </div>
  )
}