import {useEffect, useState} from 'react'
import ConfirmModal from '../admin/ConfirmModal/ConfirmModal'

export default function TestPage() {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmData, setConfirmData] = useState ({})
  const [modalUse, setModalUse] = useState('')


  const showEnd = () => {
    setModalUse('endBidding')
    setShowConfirmModal(true)
  }

  const showClose = () => {
    setModalUse('closeAuction')
    setShowConfirmModal(true)
  }

  const showConfirm = () => {
    setModalUse('confirmCharges')
    setConfirmData({
      itemsCharged: 5,
      totalCharged: 1250,
    })
    setShowConfirmModal(true)
  }

  const showMarking = () => {
    setModalUse('markingItemsAsPaid')
    setConfirmData({
      itemsMarkedPaid: 5,
      totalMarkedPaid: 1250,
    })
    setShowConfirmModal(true)
  }

  const showItem = () => {
    setModalUse('itemCharge')
    setConfirmData({
      itemName: '[Item Title]',
      attendeeName: '[Attendee Name]',
      amountDue: 500,
    })
    setShowConfirmModal(true)
  }

  const showModify = () => {
    setModalUse('modifyItemAmountDue')
    setConfirmData({
      itemName: '[Item Title]',
      attendeeName: '[Attendee Name]',
      amountDue: 500,
    })
    setShowConfirmModal(true)
  }

  const showRemind = () => {
    setModalUse('paymentReminderEmail')
    setConfirmData({
      attendeeCount: 20
    })
    setShowConfirmModal(true)
  }

  return (
    <>
      <br />
      <button onClick={() => showEnd()}>Show End Live Bidding Modal</button>
      <br />
      <br />
      <button onClick={() => showClose()}>Show Close Auction Modal</button>
      <br />
      <br />
      <button onClick={() => showConfirm()}>Show Confirm Charges Modal</button>
      <br />
      <br />
      <button onClick={() => showMarking()}>Show Confirm Marking Items As Paid Modal</button>
      <br />
      <br />
      <button onClick={() => showItem()}>Show Confirm Item Charge Modal</button>
      <br />
      <br />
      <button onClick={() => showModify()}>Show Modify Item Amount Due Modal</button>
      <br />
      <br />
      <button onClick={() => showRemind()}>Show Send Payment Reminder Email Modal</button>

      {showConfirmModal && <ConfirmModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal} 
        modalUse={modalUse} data={confirmData} /> }
    </>
  )
}

