import themeCss from '../../components/sass/theme.module.scss'
import css from '../../auctions/sass/auctions.module.scss'
import adminCss from '../sass/adminpage.module.scss'


export default function TestAuctionButtons({ sendActionToIframe }) {

  return (
    <div className={adminCss['test-auction-buttons']}>
      <h4>User Actions</h4>
      <button className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['sim-button']}`} 
        onClick={()=> sendActionToIframe('attending')}>
          Simulate User Attending</button>
      <button className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['sim-button']}`} 
        onClick={()=> sendActionToIframe('notAttending')}>
          Simulate User Not Attending</button>
      <button className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['sim-button']}`} 
        onClick={()=> sendActionToIframe('checkIn')}>
          Simulate Check In</button>
      <button className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['sim-button']}`} 
        onClick={()=> sendActionToIframe('checkOut')}>
          Simulate Check Out</button>

      <h4>Auction Actions</h4>
      <button className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['sim-button']}`} 
        onClick={()=> sendActionToIframe('auctionLive')}>
          Simulate Setting Auction Live</button>
      <button className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['sim-button']}`} 
        onClick={()=> sendActionToIframe('auctionEnd')}>
          Simulate Auction End</button>

    </div>
  )
}