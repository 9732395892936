import themeCss from '../components/sass/theme.module.scss'
import css from './sass/auctions.module.scss'
import UnregisterButton from './UnregisterButton'

export default function AuctionStatusPreview({ auctionDetail, auctionUser, userAttending}) {

  return (
    <div className={css['auction-container']}>

      <h2 id="landingPageTitle">
        {auctionDetail.Title}
      </h2>
      
      <div className={themeCss['blue-background']}>
        <p className={css['auction-description-unreg']}>Please check back soon, the auction has not been made public yet! If you believe this is an error, please contact the auction administrator.</p>
        <p className={css['auction-description-unreg']}>{auctionDetail.Description}</p>
      </div>

      {userAttending && <UnregisterButton auctionDetail={auctionDetail} auctionUser={auctionUser} />}
    </div>
  )
}