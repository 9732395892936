import { showLoading } from 'services/util'

import { unregisterUser } from '../api'

import css from './sass/auctions.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function UnregisterButton({ auctionDetail, auctionUser }) {

  const unregisterFromAuction = async (auctionDetail) => {
    showLoading(true)

    // format auctionDetail for unregister needed for api call to work
    const found = auctionUser.MyAuctions.find((ele) => ele.AuctionId === auctionDetail.AuctionId)
    auctionDetail.RegistrationId = found.RegistrationId
  
    const unregisterResponse = await unregisterUser(auctionDetail, auctionUser)
    // auctionDetail.RegistrationId = null
    showLoading(false)
  }


  return (
        
    <div className={css['auction-unregister-container']}>
      <div>
        <p>
          This is for testing only. 
        </p>
        <button className={`${themeCss['FilledBlue']} ${themeCss['button']}`}
              onClick={() => unregisterFromAuction(auctionDetail)}
            >Cancel Sign Up For Auction</button>
      </div>
    </div>
  )
}