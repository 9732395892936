import { useState, useEffect } from 'react'
import { useMutation } from "@tanstack/react-query"

import { showLoading } from 'services/util'
import checkmark from 'images/check-green.svg'

import { updateAuctionItemMutation } from '../graphql/Mutations'
import { graphql  } from '../api'

import css from './sass/createedit.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import UploadFiles from './UploadFiles'
import { getImageUrl } from '../util/images'


export default function ItemEdit({ auction, item, refreshAdminAuctionQuery }) {

  const [description, setDescription] = useState(item.Description)
  const [donor, setDonor] = useState(item.Donor)
  const [imageFilename, setImageFilename] = useState(item.ImageFilename)
  const [itemCode, setItemCode] = useState(item.ItemCode)
  const [minRaise, setMinRaise] = useState(item.MinRaise)
  const [startingBid, setStartingBid] = useState(item.StartingBid)
  const [title, setTitle] = useState(item.Title)
  const [value, setValue] = useState(item.Value)

  const [imageUrl, setImageUrl] = useState('')
  const [showEditImage, setShowEditImage] = useState(false)

  const [disableEdit, setDisableEdit] = useState(true)

  const registeredTitle = auction.Items.filter(ele => ele.ItemId === item.ItemId).map(ele => ele.Title)[0]

  const [titles, setTitles] = useState()
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const [updatedItem, setUpdatedItem] = useState()

  useEffect(() => {
    if (description !== item.Description ||
    donor !== item.Donor ||
    imageFilename !== item.ImageFilename ||
    itemCode !== item.ItemCode ||
    minRaise !== item.MinRaise ||
    startingBid !== item.StartingBid ||
    title !== item.Title ||
    value !== item.Value) {
      setDisableEdit(false)
    } else setDisableEdit(true)
  }, [item, description, donor, imageFilename, itemCode, minRaise, startingBid, title, value])

  
  const {data: updateItemResponse, error, isPending, mutate: updateItem } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateAuctionItemMutation, variables: {input: input} })
    },
  })

  const onSubmit = async (e) => {
    e.preventDefault()
    
    const input = {
      ItemId: item.ItemId,
      Title: title,
      ImageFilename: imageFilename,
      Donor: donor,
      MinRaise: minRaise,
      Description: description,
      StartingBid: startingBid,
      Value: value
    }

    console.log('input', input)

    setUpdatedItem(input)
    updateItem(input)
  }

    // use effect for auction banner image
    useEffect(() => {
      const url = getImageUrl(auction?.AuctionCode, item.ItemCode, imageFilename)
      setImageUrl(url)
    }, [auction, item])

  useEffect(() => {
    showLoading(isPending)

    if (error) {
      console.log("Item not updated: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (updateItemResponse && !updateSuccess) {
      console.log(updateItemResponse)
      console.log('Item updated: ', updateItemResponse.data.updateAuctionItem.ItemId)
      const newItem = {...updatedItem, ItemId: updateItemResponse.data.updateAuctionItem.ItemId, ItemCode: item.ItemCode}
      setUpdatedItem(newItem)
      console.log('set updated item again with the new stuff', newItem)
      refreshAdminAuctionQuery()
      setUpdateSuccess(true)

      console.log('after set create success')
    }
  }, [updateItemResponse, error, isPending, updatedItem])



  useEffect(() => {
    let currentTitles = auction.Items.map(item => item.Title)
    setTitles(currentTitles)

  }, [auction])

  useEffect(() => {
    let current = {...disableSubmit}

    if (titles?.includes(title) && title !== registeredTitle) {
      current = true
      if (disableSubmit === false) setDisableSubmit(current)
    }
    else {
      current = false
      if (disableSubmit === true) setDisableSubmit(current)
    }

  }, [title, titles, disableSubmit, registeredTitle])

  const toggleShowEditImage = () => {
    let toggled = !showEditImage
    setShowEditImage(toggled)
  }

  if (updateSuccess) {
    return (
      <>
        <div className={css['success-container']}>
          <img className={css['success-checkmark']} src={checkmark} alt={'Payment Successful'}/>
          <p>Item updated successfully!</p>
        </div>
      </>
    )
  }
  
  if (showEditImage) {
    return (
      <div className={css['page-container']}>
        <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`} onClick={()=>toggleShowEditImage()} >Back to Item Edit</button>
        <UploadFiles imageType={'item'} auction={auction} item={item} refreshQuery={refreshAdminAuctionQuery}/>
      </div>
    )
  }

  return (
    <div className={css['page-container']}>
      <form onSubmit={onSubmit}>
        <h2>Edit Item "{item.Title}" for {auction.Title}</h2>
        <div className={`${css['item-container']}`} >
          <div className={css['create-item-input']}>
            <label>Title
              {disableSubmit && <span className={css['error-text']}>Title already in use, select another!</span>}
            </label>
            <input type='text'
              value={title}
              onChange={(e) => setTitle(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Fair Market Value (in dollars)</label>
              <input type = 'number'
                value={value}
                onChange={(e) => setValue(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Starting Bid (in dollars)</label>
              <input type='text'
                value={startingBid}
                onChange={(e) => setStartingBid(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Minimum Raise (in dollars)</label>
              <input type='text'
              data-type="currency"
              value={minRaise}
              onChange={(e) => setMinRaise(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Item Number <span className={css['label-notice']}>(cannot be edited after initialization)</span>
            </label>
              <input type='number'
                value={itemCode}
                disabled={true}
                onChange={(e) => setItemCode(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Donor</label>
              <input type='text'
                value={donor}
                onChange={(e) => setDonor(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Item Image Upload <span className={css['acceptable-text']}>(Accepted file types: .jpg, .jpeg, .png) </span></label>
            <p>Current filename: {imageFilename}</p>
              <div className={css['thumbnail-container']}>
                <img src={imageUrl} />
                <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${css['button-image-edit']}`} onClick={()=>toggleShowEditImage()} >Edit Image</button>
              </div>
            <p>(Image to be uploaded on next page)</p>
          </div>
        </div>
        <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`}
          disabled={disableSubmit || disableEdit} type="submit" >Edit Item</button>
      </form>
    </div>
  )
}