import { useState, useEffect } from 'react'
import { useMutation } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { graphql } from '../api'
import { updateAuctionMutation } from '../graphql/Mutations'

import css from './sass/createedit.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import UploadFiles from './UploadFiles'
import { getAuctionStatus } from '../util/dateString'
import { getImageUrl } from '../util/images'

export default function AuctionEdit({ auction, refreshAdminAuctionQuery}) {
  const [auctionCode, setAuctionCode] = useState(auction?.AuctionCode)
  const [auctionLocation, setAuctionLocation] = useState(auction?.AuctionLocation)
  const [bannerImage, setBannerImage] = useState(auction?.BannerImageName)
  const [description, setDescription] = useState(auction?.Description)
  const [endTime, setEndTime] = useState(auction?.EndTime)
  const [minimumRaise, setMinimumRaise] = useState(auction?.MinimumRaise)
  const [needsCheckin, setNeedsCheckin] = useState(auction?.NeedsCheckin || false)
  const [startTime, setStartTime] = useState(auction?.StartTime)
  const [title, setTitle] = useState(auction?.Title)
  const [organizerAddress, setOrganizerAddress] = useState(auction?.OrganizerAddress)
  const [organizerLogo, setOrganizerLogo] = useState(auction?.OrganizerLogoName)
  const [organizerName, setOrganizerName] = useState(auction?.OrganizerName)
  const [organizerWebsite, setOrganizerWebsite] = useState(auction?.OrganizerWebsite)

  const [disableEdit, setDisableEdit] = useState(true)
  const [bannerUrl, setBannerUrl] = useState('')

  const currentTime = new Date()
  const liveStatus = getAuctionStatus(currentTime, auction?.StartTime, auction?.EndTime)
  
  let isReadOnly = auction.Status === 'CLOSED' || liveStatus !== 'before'
  
  const {data: updateAuctionResponse, error, isPending, mutate: saveAuction } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateAuctionMutation, variables: {input: input} })
    },
  })

  useEffect(() => {
    if (auctionCode !== auction.AuctionCode ||
    auctionLocation !== auction.AuctionLocation ||
    // bannerImage !== auction.BannerImageName ||     // commented out because upload files automatically updates the auction
    description !== auction.Description ||
    endTime !== auction.EndTime ||
    minimumRaise !== auction.MinimumRaise ||
    needsCheckin !== auction.NeedsCheckin ||
    startTime !== auction.StartTime ||
    title !== auction.Title ||
    organizerAddress !== auction.OrganizerAddress ||
    organizerLogo !== auction.OrganizerLogoName ||
    organizerName !== auction.OrganizerName ||
    organizerWebsite !== auction.OrganizerWebsite) {
      setDisableEdit(false)
    } else setDisableEdit(true)
  }, [auction, auctionCode, auctionLocation, bannerImage, description, endTime, minimumRaise, needsCheckin, startTime, title, organizerAddress, organizerLogo, organizerName, organizerWebsite])


  //needed to refresh the auction details from inside upload files component properly
  useEffect(() => {
    setBannerImage(auction.BannerImageName) // fine maybe?
  }, [auction])

  useEffect(() => {
    showLoading(isPending)

    if (error) {
      console.log("Auction not created: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (updateAuctionResponse) {
      console.log(updateAuctionResponse)
      console.log('Auction updated: ', updateAuctionResponse.data.updateAuction.AuctionId)
      refreshAdminAuctionQuery()
    }
  }, [updateAuctionResponse, error, isPending, refreshAdminAuctionQuery])

  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, 'banner', bannerImage)
    setBannerUrl(url)
  }, [auction, bannerImage])

  console.log('needs', needsCheckin)

  const toggleCheck = () => {
    let newValue = !needsCheckin
    setNeedsCheckin(newValue)
  }

  const onSubmit = async () => {
    console.log('submitted auction', auction)

    console.log('needs check in inside submit', needsCheckin)

    const input = {
        Title: title,
        StartTime: startTime,
        OrganizerWebsite: organizerWebsite,
        OrganizerName: organizerName,
        OrganizerLogoName: organizerLogo,
        OrganizerAddress: organizerAddress,
        MinimumRaise: minimumRaise,
        NeedsCheckin: needsCheckin,
        EndTime: endTime,
        BannerImageName: bannerImage,
        Description: description,
        AuctionLocation: auctionLocation,
        // AuctionCode: auctionCode
        AuctionId: auction.AuctionId,
        Status: auction.Status
      }
    saveAuction(input)
  }

  return (
    <div className={css['edit-container']}>
      <div className={css['item-container']}>
        <div className={css['edit-item-input']}>
          <label>Title</label>
          <input type='text'
            value={title}
            disabled={isReadOnly}
            onChange={(e) => setTitle(e.target.value)}/>  
        </div>
        <div className={css['edit-item-input']}>
          <label>Description</label>
            <textarea 
              value={description}
              disabled={isReadOnly}
              rows="6"
              onChange={(e) => setDescription(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Auction Location</label>
            <input type='text'
              value={auctionLocation}
              disabled={isReadOnly}
              onChange={(e) => setAuctionLocation(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Auction Code <span className={css['label-notice']}>(can not be edited after initialization)</span></label>
            <input type='text'
              value={auctionCode}
              disabled={true}
              onChange={(e) => setAuctionCode(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Minimum Raise (in dollars)</label>
            <input type='text'
              data-type="currency"
              value={minimumRaise}
              disabled={isReadOnly}
              onChange={(e) => setMinimumRaise(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Start Time</label>
            <input type='datetime-local'
              disabled={isReadOnly}
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>End Time</label>
            <input type='datetime-local'
              disabled={isReadOnly}
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}/>
        </div>

        <div className={css['create-item-input-checkbox']}>
          <input type='checkbox'
            checked={needsCheckin}
            disabled={isReadOnly}
            onChange={(e) => {toggleCheck()}
            }/> Auction requires check in
          <p>Checking this box will generate a QR code for the attendee. This QR code will be required to be scanned at the event in order for the attendee to participate in the auction. Check this box for events requiring a ticket for physical in-person auction events.</p>
          <p>Leaving this box unchecked will automatically check the user in upon registration. Leave the box unchecked if the auction will be open online for bidding before the physical event occurs, or for free events.</p>
        </div>

        <h5>Organizer Information</h5>
        <div className={css['edit-item-input']}>
          <label>Organizer Address</label>
            <input type='text'
              value={organizerAddress}
              disabled={isReadOnly}
              onChange={(e) => setOrganizerAddress(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Organizer Name</label>
            <input type='text'
              value={organizerName}
              disabled={isReadOnly}
              onChange={(e) => setOrganizerName(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Organizer Website</label>
            <input type='text'
              value={organizerWebsite}
              disabled={isReadOnly}
              onChange={(e) => setOrganizerWebsite(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Banner Image Upload <span className={css['acceptable-text']}>(Accepted file types: .jpg, .jpeg, .png) </span></label>
          <div>
            <p>Current filename: {bannerImage}</p>
            <div className={css['thumbnail-container']}>
              <img src={bannerUrl} alt={`${auction.Title} banner thumbnail`}/>
            </div>
            <UploadFiles imageType={'auction'} auction={auction} 
              refreshQuery={refreshAdminAuctionQuery} pageType={'editAuction'} />
          </div>
        </div>
      </div>
      <button disabled={isReadOnly || disableEdit} className={`${themeCss['FilledBlue']} ${themeCss['button']} ${themeCss['centered-button']}`} onClick={() => onSubmit()} type='submit'>Edit Auction</button>
    </div>
  )
}