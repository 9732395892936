import { getAuctionUser } from '../redux/selectors'
import { useSelector } from 'react-redux'
import { cardValidCheck } from '../payment/util'

import { history } from 'services/history.js'

import AmEx from '../components/images/payment-cards/american-express.svg'
import Discover from '../components/images/payment-cards/discover.svg'
import Mastercard from '../components/images/payment-cards/mastercard.svg'
import Visa from '../components/images/payment-cards/visa.svg'

import css from './sass/mypages.module.scss'
import themeCss from '../components/sass/theme.module.scss'

const ccTypes = {
  'Visa': { cardImage: Visa, cardAltText: 'Visa logo'},
  'MasterCard': { cardImage: Mastercard, cardAltText: 'Mastercard logo'},
  'Discover': { cardImage: Discover, cardAltText: 'Discover logo'},
  'American Express': { cardImage: AmEx, cardAltText: 'American Express logo'},
}

export default function MyPayments() {

  const auctionUser = useSelector(getAuctionUser)
  const userCards = auctionUser?.PaymentMethods

  // userCards.pop()
  // userCards[0].Expires = "07/2024"

  if (!userCards) return (<div className={css['mypayments-nocard']}>
      <p>No payments on file</p>
      <button onClick ={(e) => {history.push({pathname: '/flow/paymentupdate' }) }}
        className={`${themeCss['button']} ${themeCss['FilledYellow']} ${css['mypages-button']}`}>
        Add Payment Method
      </button>
    </div>)

  return (
    <>
    
    <div className={css['mypages-header']}>
      <h2 className={css['mypages-headings']}>My Payment Info</h2>

      <p>Here you may update the payment information you have saved for UCLA Auctions.</p>
    </div>
      <div className={themeCss['blue-background']}>
        <div className={css['mypages-card']}>
          {userCards.length !== 0 && userCards.map((card) => {
            return (<div key={`${card.CardType}-${card.Expires}`}>
              <h3 className={css['mypages-headings']}>Saved Payment Card</h3>
              <div className={`${css['mypayments-savedcard']} ${cardValidCheck(card.Expires) ? '' : css['mypayments-expiring']}`}>
                <p>EXISTING CARD ON FILE</p>
                <img src={ccTypes[card.CardType].cardImage} alt={ccTypes[card.CardType].cardAlt} className={css['mypayments-image']}/>
                <div className={css['mypayments-savedcard-details']}>
                  {/* <p>{card.NameOnCard}</p> */}
                  <p>Joe Bruin</p>
                  <p>XXXX - XXXX - XXXX - {card.Last4}</p>
                  <p> Expires: {card.Expires}</p>

                </div>
              </div>
              {!cardValidCheck(card.Expires) && <p className={css['mypayments-expiremessage']}>Card is expired, or will be soon, please update below!</p>}
            </div>)
          })}
          {userCards.length === 0 && <p>Please add a card to use as payment within the auction app below</p>}
          
          <button onClick ={(e) => {history.push({pathname: '/flow/paymentupdate' }) }}
                  className={`${themeCss['button']} ${themeCss['FilledYellow']} ${css['mypages-button']}`}>
            {userCards.length === 0 ? 'Add' : 'Update'} Payment Info
          </button>
        </div>
      </div>
    
    </>
  )
}