import React from 'react'
import infoOrange from './images/info-icon-orange.svg'
import infoYellow from './images/info-icon-yellow.svg'
import infoBlue from './images/info-icon-blue.svg'
import css from './sass/infopopup.module.scss'

export default function InfoPopUp(props) {


  const { altMessage, mainText, position } = props
  
  return (
    <div>
      <img className={css.icon} src={infoBlue} alt={altMessage} />
      
      <div className={css['tooltip']}>
        <div className = {`${css['tooltip-carat']} ${css[position]}`}></div>
        <div className={`${css['tooltip-container']} ${css[position]}`}>
          <p>{mainText}</p>
        </div>
      </div>
      
    </div>
  )
}
