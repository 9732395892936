import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useQueryClient } from "@tanstack/react-query"

import { history } from 'services/history.js'
import { getAllUpcomingAuctions } from '../../api'

import css from './sass/adminpage.module.scss'

import AuctionCard from '../AuctionCard'
import { showLoading } from 'services/util'


export default function AdminPublicAuctionPage(props) {

  const [showAuctionModal, setShowAuctionModal] = useState(false)
  const [liveAuctionList, setLiveAuctionList] = useState([])
  const [endedAuctionList, setEndedAuctionList] = useState([])
  const [upcomingAuctionList, setUpcomingAuctionList] = useState([])
  const [invalidateQuery, setInvalidateQuery] = useState(false)
  const [refresh, setRefresh] = useState(false)

  //used to pass down codes for create auction to validate for no duplicate codes
  const [auctionCodes, setAuctionCodes] = useState([])

  //to refresh query on changes
  const queryClient = useQueryClient()

  // load auction data by code, so it will work for deep-linking
  const { data: auctions, error, isLoading } = useQuery({
    queryKey: ["allAuctionListInPublic"],
    queryFn: async () => await getAllUpcomingAuctions(),
    staleTime: 0
  })

  console.log(auctions)

  useEffect(() => {
    showLoading(isLoading)

    if (error) {
      console.log("Auctions not loaded: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (auctions) {
      console.log('loaded results, ', auctions)
    }
  }, [auctions, error, isLoading])

  const refreshUpcomingAuctionsQuery = () => {
    queryClient.invalidateQueries(["allAuctionListInPublic"])
  }

  const showCreateAuctionModal = () => {
    let newValue = !showAuctionModal
    setShowAuctionModal(newValue)
  }

  useEffect(() => {
    if (!auctions) return

    // const allUpcomingAuctions = auctions.sort((a, b) => (a.StartTime < b.StartTime) ? -1 : (a.StartTime > b.StartTime) ? 1 : 0).map((auction) => { return {...auction, 'displayData': false}})
    const allPublicAuctions = auctions.filter((auction) => auction.Status === 'PUBLIC')

    let upcomingAuctions = []
    let liveAuctions = []
    let endedAuctions = []

    const now = new Date()
    const nowStr = now.toISOString()
    
    allPublicAuctions.forEach((auction) => {
      
      if (auction.StartTime < nowStr) {
        if (auction.EndTime < nowStr) endedAuctions.push(auction)
        else liveAuctions.push(auction)
      }
      else upcomingAuctions.push(auction)
    })

    setLiveAuctionList(liveAuctions)
    setEndedAuctionList(endedAuctions)
    setUpcomingAuctionList(upcomingAuctions)
    setAuctionCodes(auctions.map(auction => auction.AuctionCode))
  }, [auctions])


  useEffect(() => {
    if (refresh) setRefresh(false)
  }, [refresh])

  return (
    <div>
      {liveAuctionList && <div className={css['auction-blocks']}>
        <h2 className={css['auction-headers']}>Live Auctions</h2>
        {liveAuctionList.map((auction) => <AuctionCard state={'live'} auction={auction} key={auction.AuctionId} />)}
      </div>}

      {upcomingAuctionList && <div className={css['auction-blocks']}>
        <h2 className={css['auction-headers']}>Upcoming Auctions</h2>
        {upcomingAuctionList.map((auction) => <AuctionCard state={'upcoming'} auction={auction} key={auction.AuctionId} />)}
      </div>}

      {upcomingAuctionList && <div className={css['auction-blocks']}>
        <h2 className={css['auction-headers']}>Ended Auctions</h2>
        {endedAuctionList.map((auction) => <AuctionCard state={'ended'} auction={auction} key={auction.AuctionId} />)}
      </div>}

      {/* Demo: to link between admin pages, use props.history, to link to pages outside admin, use imported history*/}
      <button onClick={(e) => {history.push({pathname: '/auctions'})}}>Demo Link to non-admin Page</button><br/>
      <button onClick={(e) => {props.history.push({pathname: '/admin/createauction'})}}>Demo New Auction Image Upload</button><br/>

      {/* Link will always use props.history */}
      <Link to={{ pathname: '/admin/createauction' }}>Demo Auction Image Upload</Link>

    </div>
  )
}